const HujjatUzEIMZOClient = {
	__eif: null,

	checkVersion: async function () {
		return this._call("checkVersion", null)
	},

	installApiKeys: async function () {
		return this._call("installApiKeys", null)
	},

	listAllUserKeys: async function () {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this._call("listAllUserKeys", null)

				result.items.forEach((x) => {
					x.validFrom = new Date(x.validFrom)
					x.validTo = new Date(x.validTo)
				})

				resolve(result.items)
			} catch (e) {
				return []
			}
		})
	},

	listAllCertKeys: async function (itemIdGen, itemUiGen, success, fail) {
		this._call(
			"listAllCertKeys",
			null,
			function (data) {
				if (data && data.length > 0) {
					var items = []
					for (var rec in data) {
						var vo = data[rec]
						var itmkey = itemIdGen(vo, rec)
						var itm = itemUiGen(itmkey, vo)
						items.push(itm)
					}
					success(items)
				} else success(data)
			},
			fail
		)
	},

	loadKey: async function (itemObject) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this._call("loadKey", { itemObject: itemObject })

				resolve({
					cert: itemObject,
					id: result.id
				})
			} catch (e) {
				reject(e)
			}
		})
	},

	changeKeyPassword: async function (itemObject, success, fail) {
		await this._call("changeKeyPassword", { itemObject: itemObject }, success, fail)
	},

	isIDCardPlugged: async function () {
		return true
	},

	createPkcs7: async function (id, content, timestamper) {
		return new Promise(async (resolve, reject) => {
			try {
				let data = await this._call("createPkcs7", { id: id, data: content })

				let pkcs7 = data.pkcs7

				if (timestamper) {
					let sn = data.serialNumber

					let tst = await timestamper(data.signatureHex)

					const timestamp = await this._call("attachTimestampTokenPkcs7", {
						data: pkcs7,
						serialNumber: sn,
						timestampToken: tst
					})

					resolve(timestamp.pkcs7)
				} else {
					resolve(pkcs7)
				}
			} catch (e) {
				reject(e)
			}
		})
	},

	appendPkcs7Attached: async function (id, data, timestamper, success, fail) {
		await this._call(
			"appendPkcs7Attached",
			{ id: id, data: data, timestamper: timestamper },
			function (data) {
				success(data.pkcs7)
			},
			fail
		)
	},

	/**
	 * @param {string} action
	 * @param {object} args
	 * @return {Promise<unknown>}
	 * @private
	 */
	_call: async function (action, args) {
		return new Promise(async (resolve, reject) => {
			await this._checkAndCreateModule()

			const response = await this._syncData({ action: action, arguments: args })

			if (response) {
				if (response.hasOwnProperty("success")) {
					if (response.success instanceof Object) resolve(response.success)
					else resolve()
				} else if (response.hasOwnProperty("fail")) reject(response.fail.error, response.fail.reason)
				else reject(null, "Response is empty")
			} else reject("Module error", null)
		})
	},

	_syncData: function (data) {
		return new Promise((resolve) => {
			try {
				const channel = new MessageChannel()

				// console.warn(data)
				channel.port1.onmessage = (event) => resolve(JSON.parse(event.data))
				this.__eif.contentWindow.postMessage(JSON.stringify(data), "https://hujjat.uz", [channel.port2])
			} catch (e) {
				resolve(JSON.stringify({ fail: [e, null] }))
			}
		})
	},
	_checkAndCreateModule: function () {
		if (this.__eif != null) return

		return new Promise((resolve) => {
			this.__eif = document.createElement("iframe")
			this.__eif.style.display = "none"
			this.__eif.src = "https://hujjat.uz/eimzo/tunnel.html"
			this.__eif.onload = () => resolve()
			document.body.appendChild(this.__eif)
		})
	},

	install: async function () {
		await this.checkVersion()

		await this.installApiKeys()
	}
}

export default HujjatUzEIMZOClient
