<template>
    <div class="ProfileViewFragment">
        <section class="ProfileView">
            <ProfileDetails />
            <Divider />
            <Container v-show="false">
                <div class="ProfileView__gawp--permissions">
                    <div class="permission-header">
                        <h3 class="permission-header__typography">Permissions:</h3>
                    </div>
                    <ul class="permissions-list">
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                    </ul>
                </div>
            </Container>
        </section>
        <!-- PROFILE SETTINGS SECTION -->
        <section class="ProfileViewSettings">
            <div class="ProfileViewSettings__header">
                <h3 class="ProfileViewSettings__header--typography">
                    {{ $l("platon.security-title", "Хавфсизлик") }}
                </h3>
            </div>
            <Container class="ProfileViewSettings__details">
                <Accordion
                    icon="mdi mdi-lock-reset"
                    :header="$l('platon.change-password-title', 'Парольни ўзгартириш')"
                    :disabled="!passwordChangingAccessible"
                >
                    <PasswordSettingsSection />
                </Accordion>
                <Accordion icon="mdi mdi-shield-edit" :header="$l('platon.otp-enable', 'OTP-ни йоқиш')">
                    <MFASection />
                </Accordion>
                <Accordion :disabled="true" icon="mdi mdi-key" header="Е-имзо">
                    <!-- Currently Accordion has been disabled -->
                </Accordion>
            </Container>
        </section>
    </div>
</template>

<script>
import Container from "./components/container"
import Divider from "./components/divider"
import Accordion from "./components/accordion"
import PasswordSettingsSection from "./sections/PasswordSettingsSection"
import MFASection from "./sections/MFASection"
import ProfileDetails from "./sections/ProfileDetails"

export default {
    name: "ProfileView",
    props: {},
    components: {
        Container,
        Divider,
        Accordion,
        PasswordSettingsSection,
        MFASection,
        ProfileDetails
    },
    data() {
        return {
            passwordChangingAccessible: this.$projectInfo.is_change_pass_enabled
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./profileDetails.scss";
</style>
