import ParametrizedString from "@Platon/core/ParametrizedString"
import { EXPORT_RESOURCE_URL } from "@Platon/const"
import { appendSlash } from "@Platon/core/helpers"
import ImportFileDialog from "@Platon/components/misc/ImportFileDialog.vue"
import LoadingView from "@Platon/components/extended/LoadingView.vue"

const linkHandlers = new Map()

export default {
	methods: {
		/**
		 * @param {function} fn
		 */
		registerLinkHandler(fn) {
			linkHandlers.set(this, fn)
		},

		unregisterLinkHandler() {
			linkHandlers.delete(this)
		},

		/**
		 * @param {string} link
		 * @param {object} ctx
		 * @param {Window?} win
		 */
		async navigateTo(link, ctx = {}, win) {
			if (!link) return

			link = this.replaceParametrizedText(link, ctx)

			if (link.match(/^(http|https)/gi)) {
				window.open(link, "_blank")
				return
			}

			link = appendSlash(link)

			let resolved = this.$router.resolve(link)

			await this.navigateToRoute(resolved.route, win)
		},

		/**
		 * @param {Route} route // todo tekshirish kerak
		 * @param {Window?} win
		 */
		async navigateToRoute(route, win) {
			win = win || window

			if (!route.query._mid && this.$route.query._mid) {
				route.query._mid = this.$route.query._mid
			}

			if (linkHandlers.size > 0) {
				let handled = Array.from(linkHandlers.values()).some((fn) => fn(route))

				if (handled) return
			}

			if (route.fullPath.startsWith("/import/")) {
				await this.handleImport(route)
			} else if (route.name === "form" || route.name === "page") {
				let openMode = route.name === "form" ? "blank" : "self"
				await this.handleViewsInModal(route, win, openMode)
			} else if (route.name === "system") {
				if (route.path === "/system/logout") {
					this.$platonApp.showLogoutDialog()
				}
			} else {
				/***
				 * @type {string}
				 */
				let openVariant = route.query["_target"] || ""

				if (openVariant.includes("blank")) {
					// window.close()
					win.open(win.location.origin + this.$router.history.base + route.fullPath, "_blank")

					return
				}

				await this.$router.push(route)
			}
		},

		/**
		 * @param {Route|string|Location} route
		 * @param {Window} win
		 * @param {string} defaultMode
		 */
		async handleViewsInModal(route, win, defaultMode = "blank") {
			let openVariant = route.query["_target"] || defaultMode || "blank"

			if (openVariant === "self") {
				await this.$router.push(route)
			} else if (openVariant.startsWith("modal")) {
				// extract width from url
				let width = 0

				try {
					width = Number(/modal_(\d+)/g.exec(openVariant)[1])
				} catch (e) {
					// ignored
				}

				let widthSize = width === 0 ? "80%" : width > 100 ? `${width}px` : `${width}%`

				let modalInnerComponent

				if (route.name === "form") {
					modalInnerComponent = () => {
						return {
							component: import("@Platon/components/form/PlatonModalForm.vue"),
							loading: LoadingView,
							delay: 200
						}
					}
				} else {
					modalInnerComponent = () => {
						return {
							component: import("@Platon/views/public/PlatonPageView.vue"),
							loading: LoadingView,
							delay: 200
						}
					}
				}

				this.$modal.show(
					modalInnerComponent,
					{ route: route },
					{
						draggable: ".modal-form-header",
						width: widthSize,
						height: "auto",
						// maxHeight: window.screen.availHeight * 0.9,
						scrollable: true,
						classes: ["form-modal"],
						clickToClose: false
					}
				)
			} else {
				win.open(win.location.origin + this.$router.history.base + route.fullPath, "_blank")
			}
		},

		/**
		 * @param {string} link
		 * @param {Object} ctx
		 *
		 * @return {{link: string, type:string}}
		 */
		resolveNavigateTo(link, ctx = {}) {
			if (!link) return {}

			link = this.replaceParametrizedText(link, ctx)

			if (link === null || link === undefined) return {}

			if (link.startsWith("export/")) {
				return {
					type: "external",
					target: "self",
					link: EXPORT_RESOURCE_URL(link.substr(7))
				}
			}

			let target = link.match(/_target=(\w+)/)

			if (target && target[1]) {
				target = target[1]
			}

			if (link.match(/^(http|https)/gi)) {
				return {
					type: "external",
					link: link,
					target
				}
			}

			link = appendSlash(link)

			let resolved = this.$router.resolve(link)
			if (resolved && resolved.route) {
				return {
					type: "internal",
					link: resolved.route.fullPath,
					target,
					route: resolved.route
				}
			} else {
				return {
					type: "internal",
					link,
					target
				}
			}
		},

		/**
		 * @param {string} text
		 * @param {Object} context
		 */
		replaceParametrizedText(text, context) {
			return ParametrizedString.replace(text, context)
		},

		/**
		 * @param {Element} $el
		 * @param {Object} context
		 */
		replaceDefaultLinkWithRouter($el, context) {
			if (!$el) {
				return
			}

			$el.querySelectorAll("a").forEach((/** HTMLLinkElement */ link) => {
				// replace original url
				link.href = this.resolveNavigateTo(link.href, context).link || link.href

				// add event listener to catch links action
				link.addEventListener("click", (e) => {
					e.preventDefault()

					this.navigateTo(e.target.href, context)
				})
			})
		},

		/**
		 *
		 * @param {String} name
		 * @param {Object} params
		 */
		openPlatonPage(name, params) {
			let route = this.$router.resolve({
				name: "page",
				params: {
					name
				},
				query: params
			})

			if (route) {
				this.handleViewsInModal(route.route, window, "self")
			}
		},

		openPlatonForm(name, params) {
			let route = this.$router.resolve({
				name: "form",
				params: {
					name
				},
				query: params
			})

			if (route) {
				this.handleViewsInModal(route.route, window)
			}
		},

		handleImport(route) {
			this.$modal.show(
				ImportFileDialog,
				{
					localRoute: route
				},
				{
					width: 500,
					height: "auto"
				}
			)
		}
	}
}
