<template>
    <div class="sidebar__footer">
        <p v-if="$const.COPYRIGHT_URL" class="sidebar__footer__link">
            <a :href="$const.COPYRIGHT_URL" target="_blank" v-html="`© ${$const.IN_SITE_COPYRIGHT}`"></a>
        </p>
        <p v-else class="sidebar__footer__link" v-html="`© ${$const.IN_SITE_COPYRIGHT}`"></p>
        <div class="sidebar__footer__stacks">
            <div class="sidebar__footer__stack">{{ $ll("platon.appVersion", [$appVersion], "{0}") }}</div>
            <div class="sidebar__footer__stack">{{ $projectInfo.version }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeftMenuFooter"
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

.sidebar__footer {
    margin: 16px;
    padding-left: 5px;
    border-left: 2px solid #b0bcc9;
}
.sidebar__footer__link {
    font-family: "Inter";
    color: #475170;
    font-size: 10px;
    margin-bottom: 0;
}
.sidebar__footer__stack {
    font-family: "Inter";
    font-size: 10px;
    margin-bottom: 0;
    color: #b0bcc9;
}
</style>
